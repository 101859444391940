import { NG_VALIDATORS, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, ElementRef } from '@angular/core';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[customTimeValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: TimeValidatorDirective,
    multi: true
  }]
})
export class TimeValidatorDirective implements Validators {

  constructor(
    private el: ElementRef
  ) { }

  validate(c: AbstractControl): ValidationErrors | null {
    this.customTimeValidator(c) ?
      this.el.nativeElement.classList.add('has-warning') :
      this.el.nativeElement.classList.remove('has-warning');
    return this.customTimeValidator(c);
  }

  checkTimeFormat(inputVal: string): boolean {
    if (inputVal && inputVal !== '') {
      let rxTF = null;
      if (inputVal.toString().length === 1 || inputVal.toString().length === 2) {
        rxTF = new RegExp('\\b((0?|1)[0-9]|2[0-3])\\b', 'g');
      } else {
        rxTF = new RegExp('\\b(((0?|1)[0-9]|2[0-3])\:([0-5][0-9]))\\b', 'g');
      }
      return rxTF.test(inputVal);
    }
    return true;
  }

  customTimeValidator(control: AbstractControl): ValidationErrors | null {
    return this.checkTimeFormat(control.value) ? null : {
      timeFormatInvalid: true,
      'has-warning': true
    };
  }
}
