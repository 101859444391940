import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateValidatorDirective } from './custom-directives/date.directive';
import { TimeValidatorDirective } from './custom-directives/time.directive';


@NgModule({
  declarations: [
    DateValidatorDirective,
    TimeValidatorDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    DateValidatorDirective,
    TimeValidatorDirective,
  ]
})
export class SharedModule { }
