export class Request {
  'id': number;
  'request_timestamp': string;
  'request_type': number;
  'requested_absence_type_obj_nr': number;
  'absence_type': string;
  'requested_begin_date': any;
  'requested_end_date': any;
  'request_text': string;
  'decision_status': string;
  'decision_comment': string;
  'last_update_timestamp': string;
  'decision_last_update_timestamp': string;
}
