import { Directive, ElementRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validators } from '@angular/forms';


@Directive({
   // tslint:disable-next-line: directive-selector
   selector: '[customDateValidator]',
   providers: [{
     provide: NG_VALIDATORS,
     useExisting: DateValidatorDirective,
     multi: true
   }]
})
export class DateValidatorDirective implements Validators {

  constructor(
    private el: ElementRef
  ) { }

  validate(c: AbstractControl): ValidationErrors | null {
    this.customDateValidator(c) ?
      this.el.nativeElement.classList.add('has-warning') :
      this.el.nativeElement.classList.remove('has-warning');
    return this.customDateValidator(c);
  }

  checkDateFormat(inputVal: string): boolean {
    if (inputVal && inputVal !== '') {
        const rxDF = new RegExp('\\b((0?)[1-9]|1[0-9]|2[0-9]|3[0-1])(\\/|\\-|\\.|\\ )(0?[1-9]|1[0-2])\\3(\\d\\d\\d\\d)\\b', 'g');
        return rxDF.test(inputVal);
    }
    return true;
  }

  customDateValidator(control: AbstractControl): ValidationErrors | null {
    return this.checkDateFormat(control.value) ? null : {
      dateFormatInvalid: true,
      'has-warning': true
    };
  }
}
